import * as React from "react";

import Puzzle from "../../Puzzle";

import romantycznaCeramika from "../../images/romantyczna-ceramika.jpg";
import map from '../../images/m5.png'
import audio from '../../../static/a5.mp3'

const Page = () => {
  return (
    <Puzzle
      title="Zagadka 5"
      author="Autorka: Mika Drozdowska"
      imageSrc={romantycznaCeramika}
      imageAlt="ceramiczne głowy na elewacji"
      mapSrc={map}
      mapAlt="Punkt zaznaczony na mapie"
      audio={audio}
      language="pl"
      languageUrl={"/riddles/4cjRSPUoRj"}
    >
      <div>
        <p>
          O tym, że dom handlowy Renoma, a niegdyś Wertheim jest przykładem
          najwspanialszej wrocławskiej architektury wie każdy kto na niego
          spojrzy.
        </p>
        <p> Zaprojektowany przez Hermanna Dernburga, otwarty 2 kwietnia
          1930 roku jest niewątpliwie najbardziej charakterystycznym i
          dominującym budynkiem placu Kościuszki. Sześciopiętrowy gmach
          wzniesiony na stalowej konstrukcji - największy szkieletowy budynek w
          Europie- był symbolem nowoczesności i progresywności miasta. Ogromne
          wrażenie robiły sale sprzedażowe, windy, pierwsze na Śląsku ruchome
          schody oraz dwa czterokondygnacyjne dziedzińce nakryte szklanymi
          świetlikami i inkrustowane afrykańskim drewnem.</p>
        <p>
          Przypatrując się jego dostojnej powierzchowności mamy słuszne wrażenie
          jakby cała kubatura mieniła się odbijając światła dnia i nocy. To
          zasługa precyzyjnie przemyślanej elewacji skonstruowanej z
          obiegających cały budynek pasów okien i znajdujących się pomiędzy nimi
          ceramicznych gzymsów oraz licowanych szkliwioną ceramiką z domieszką
          płatkowanego złota ścianek podparapetowych. To właśnie dwa wspomniane
          wyżej aspekty przyczyniły się do tego, że ten etosowy budynek wciąż
          jest częścią Wrocławia. 12 marca 1945 roku podczas oblężenia Breslau
          został zbombardowany i stał w płomieniach przez kilka dni. Szczęśliwe
          właściwości fizyczne stali oraz klinkieru, materiałów odpornych na
          wysokie temperatury, pozwoliły mu przetrwać.{" "}
        </p>
        <p>
          {" "}
          Z ceramiki wykonane były również detale fasady w postaci 104 rzeźb
          ludzkich głów oraz kwiatonów, do wykonania których przywiązywano
          wielką wagę. Nie wszystkie przetrwały bombardowanie. Odrestaurowała je
          i odtworzyła zniszczone (72 repliki) w latach 2007-2009 Paulina
          Pokorny-Ziemba. To owe głowy, a szczególnie jedna z nich wymaga
          bliższego spojrzenia i odkrycia jej nieoczywistej romantycznej
          historii. Są one dziełem dwóch rzeźbiarzy Ulricha Nitschke i Hansa
          Klakowa. To za ich sprawą Wertheim okalały podobizny ludzi z całego
          świata Miały symbolizować kosmopolityczność miasta oraz bogatą ofertę
          produktów, kryjących się za murami domu handlowego. Podnosząc głowę i
          przyglądając się egzotycznym i różnorodnym typom, które przenoszą nas
          w odległe zakątki świata naszą uwagę może zwrócić twarz kobiety -
          europejki, w charakterystycznym art decowskim czepku. Widzisz ją? To
          żona Ulricha Nitschego, który w romantycznym geście uwiecznił na
          fasadzie twarz swojej ukochanej Hadwigii. Dzięki wspaniałemu zbiegowi
          okoliczności i dostarczeniu do Wrocławia przez wnuki obu artystów
          zdjęć archiwalnych oraz oryginalnego modelu, nie ma co do tego żadnych
          wątpliwości. Rzeźba głowy żony Ulricha Nitschke powstała w 1929 roku i
          w chwili, gdy została wykonywana, Hadwig miała 47 lat. Lata mijają a
          dowód miłości artysty do swojej żony, w postaci ceramicznej rzeźby
          nadal jest z nami i zajmuje godne miejsce w przestrzeni miejskiej
          Wrocławia.
        </p>
      </div>
    </Puzzle>
  );
};

export default Page;
